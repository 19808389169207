<template>
<div class="about-page w-full h-screen md:pt-4 md:p-0 p-2 md:pb-0 pb-6">
  <popupSign :want-to-make-offer="activeCharge" @oga="retourLogin"/>
</div>
</template>

<script>
import popupSign from '@/components/popup/popupSign'

export default {
  name: 'index',
  components: {
    popupSign
  },
  data () {
    return {
      activeCharge: true,
      code: null
    }
  },
  methods: {
    retourLogin (answer) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}
.mobile{
  display: none;
}
  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }
@media only screen and (max-width: 700px) {
  .about-page{
    width: 100%;
    height: 100%;
  }
  .titre{
    font-size: 28px;
    line-height: 37px;
  }
  .sous{
    font-size: 18px;
    line-height: 24px;
  }
  .num{
    font-size: 48.4571px;
    line-height: 25px;
  }
  .web{
    display: none;
  }
  .mobile{
    display: block;
    width: 100%;
  }
}
</style>
